import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import http from '../_api/http';
const CreateArtical = () => {
  const [loading, setLoading] = useState(true);
  const [articles, setArticle] = useState([]);
  const PUBLIC_URL = process.env.REACT_APP_APIURL;
  useEffect(() => {
    // alert(process.env.REACT_APP_APIURL);
    http.get('api/blog/ApiBlogList').then((res) => {
      //  alert(res);
      // debugger
      if (res.data.status === 200) {
        setArticle(res.data.blog);
        setLoading(false);
      }
    });
  }, []);
  return (
    <section className="blog-one paddingtop  blog-one__home-two">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title fontSize">
            Newsletter
          </h2>
        </div>
        <div className="row">
          {loading
            ? <h4>View Articles Loading...</h4>
            :
            <>
              {articles.map((item, i) =>
                <div className="col-lg-3" key={i}>
                  <div className="blog-one__single">
                    <div className="blog-one__image">
                      <img src={PUBLIC_URL + "BlogImage/" + item.image} alt="" height={200} width={100} />
                      <a className="blog-one__plus" href="news-details.html">
                        <i className="kipso-icon-plus-symbol"></i>
                      </a>
                    </div>
                    <div className="blog-one__content text-center">
                      <h2 className="blog-one__title">
                        <a href="#">
                          {item.blog_title}
                        </a>
                      </h2>
                      {/* <p dangerouslySetInnerHTML={{ __html: item.blog_description }} className="blog-one__text">
                        {item.blog_description}
                      </p> */}
                      {item.pdf &&
                        <Link to={process.env.REACT_APP_APIURL + 'files/' + item.pdf} target="_blank" state={{ id: item.id }} className="thm-btn">
                          Read More
                        </Link>
                      }
                    </div>
                  </div>
                </div>
              )}
            </>
          }
        </div>
      </div>
    </section>
  );
};

export default CreateArtical;
